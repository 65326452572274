#basic-example {
  margin-left: -8px !important;
  width: 100% !important;
  max-height: 80vh !important;
  margin-top: 8px;
  border-radius: 4px;
  background-color: white;
}

#basic-example > a {
  display: block;
  border-bottom: 1px solid #d4d8dd;
  padding: 5px;
}

.rbt-input-main {
  width: 90% !important;
}

.rbt-highlight-text {
  color: red;
}

.dropdown-item:hover {
  background-color: #e2e5e8;
}
.dropdown-item.active {
  background-color: #e2e5e8;
}

.rbt {
  background-color: white;
}

.dropdown-menu::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  background-color: #f5f5f5;
}

.dropdown-menu::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #fff;
  background-image: -webkit-gradient(
    linear,
    40% 0%,
    75% 84%,
    from(#8080807e),
    to(#80808078),
    color-stop(0.6, #4a4a4a49)
  );
}

.hidenScrollbar::-webkit-scrollbar {
  display: none;
}

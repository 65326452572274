.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1F2D47;
}

.containerWidth {
  width: 100%;
}

.shadow {
  box-shadow: -15px 0 9px 0px rgba(0, 0, 0, 0.15);
}
.shadowInner {
  box-shadow: inset -2px 0 4px;
}

.checkBox {
  position: relative;
  width: 17px;
  height: 17px;
  appearance: none;
  border-radius: 4px;
  border: 2px solid #e5e7eb;
  cursor: pointer;
}

.checkBox::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  border: 3px solid #0052cc;
  border-radius: 2px;
  width: 7px;
  height: 13px;
  display: none;
  transform: translate(-48%, -66%) rotate(45deg);
  border-left-color: transparent;
  border-top-color: transparent;
}
.checkBox:checked {
  border: 2px solid #0052cc;
}
.checkBox:checked::after {
  display: block;
}

.captcha-wrapper>div{
  width: 100%;
}

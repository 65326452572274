.ant-select-focused:where(.css-dev-only-do-not-override-ph9edi).ant-select:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer)
  .ant-select-selector {
  @apply border-primaryLigh shadow-none;
}

.ant-select-focused:where(.css-dev-only-do-not-override-15rg2km).ant-select:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer)
  .ant-select-selector {
  box-shadow: none;
}

.ant-select-focused:where(.css-dev-only-do-not-override-15rg2km).ant-select:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer)
  .ant-select-selector {
  border-color: unset;
}

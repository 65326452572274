@import url('https://fonts.googleapis.com/css2?family=Chango&family=Montserrat&display=swap');

/* .fundo {
  animation: scales 3s alternate infinite;
  transform-origin: center;
}
.paoBaixo {
  animation: rotatepao 14s cubic-bezier(0.1, 0.49, 0.41, 0.97) infinite;
  transform-origin: center;
}
.paoCima {
  animation: rotatepao 7s 1s cubic-bezier(0.1, 0.49, 0.41, 0.97) infinite;
  transform-origin: center;
}
.olhos {
  animation: olhos 2s alternate infinite;
  transform-origin: center;
}

.leftSparks {
  animation: left-sparks 4s alternate infinite;
  transform-origin: 150px 156px;
}

.rightSparks {
  animation: left-sparks 4s alternate infinite;
  transform-origin: 310px 150px;
}

.olhos {
  animation: olhos 2s alternate infinite;
  transform-origin: center;
}
@keyframes scales {
  from {
    transform: scale(0.98);
  }
  to {
    transform: scale(1);
  }
}

@keyframes rotatepao {
  0% {
    transform: rotate(0deg);
  }
  50%,
  60% {
    transform: rotate(-20deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes olhos {
  0% {
    transform: rotateX(0deg);
  }
  100% {
    transform: rotateX(30deg);
  }
}

@keyframes left-sparks {
  0% {
    opacity: 0;
  }
}

.main {
  min-height: 600px;
  margin: 0px auto;
  width: auto;
  max-width: 460px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.path {
  stroke-dasharray: 300;
  stroke-dashoffset: 300;
  animation: dash 4s alternate infinite;
}

@keyframes dash {
  0%,
  30% {
    fill: 4B4B62;
    stroke-dashoffset: 0;
  }
  80%,
  100% {
    fill: transparent;
    stroke-dashoffset: -200;
  }
} */

#notfound {
  position: relative;
  height: 100vh;
}

#notfound .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.notfound {
  max-width: 520px;
  width: 100%;
  line-height: 1.4;
}

.notfound > div:first-child {
  padding-left: 200px;
  padding-top: 12px;
  height: 170px;
  margin-bottom: 20px;
}

.notfound .notfound-404 {
  position: absolute;
  left: 0;
  top: 0;
  width: 170px;
  height: 170px;
  background: #e01818;
  border-radius: 7px;
  -webkit-box-shadow: 0px 0px 0px 10px #e01818 inset, 0px 0px 0px 20px #fff inset;
  box-shadow: 0px 0px 0px 10px #e01818 inset, 0px 0px 0px 20px #fff inset;
}

.notfound .notfound-404 h1 {
  font-family: 'Chango', cursive;
  color: #fff;
  font-size: 118px;
  margin: 0px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: inline-block;
  height: 60px;
  line-height: 60px;
  margin: 0;
  padding: 0;
}

.notfound h2 {
  font-family: 'Chango', cursive;
  font-size: 68px;
  color: #222;
  font-weight: 400;
  text-transform: uppercase;
  margin: 0px;
  line-height: 1.1;
  margin: 0;
  padding: 0;
}

.notfound p {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #222;
  margin-top: 5px;
}

.notfound a {
  font-family: 'Montserrat', sans-serif;
  color: #e01818;
  font-weight: 400;
  text-decoration: none;
}

@media only screen and (max-width: 480px) {
  .notfound {
    padding-left: 15px;
    padding-right: 15px;
  }
  .notfound > div:first-child {
    padding: 0px;
    height: auto;
  }
  .notfound .notfound-404 {
    position: relative;
    margin-bottom: 15px;
  }
  .notfound h2 {
    font-size: 42px;
  }
}

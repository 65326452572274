.custom__addonAfter .ant-input-wrapper .ant-input {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.custom__addonAfter .ant-input-wrapper.ant-input-group .ant-input-group-addon {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

/* Custom css table ant */
.ant-table-thead .ant-table-cell,
.ant-table-thead .ant-table-column-sorters .ant-table-column-title {
  cursor: pointer;
  user-select: none;
}

.ant-pagination .ant-pagination-prev,
.ant-pagination .ant-pagination-next {
  border: 1px solid #d9d9d9;
  height: 32px !important;
  width: 32px !important;
  border-radius: 2px;
}

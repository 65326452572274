@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;200;300;400;500;600;700;800&display=swap');

/* Import file custom css */
@import url('./base/ui/components/CustomInput/styles.css');

/* Base UI */
@layer base {
  :root {
    /* Màu primary */
    --primaryColor: 0 88 111;
    --primaryColor-90: 46 117 138;
    --primaryColor-80: #354a5f;
    --primaryColor-70: #427cac;
    --thirdColor: #2a7487;
    --hoverColor: #a3d365;
    --hoverTextColor: #ffffff;
    --font-default: 'Roboto';
    --sidebar_itemColor: #a8b2c2;
  }

  * {
    box-sizing: border-box;
  }

  body {
    box-sizing: border-box;
    @apply font-roboto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    @apply font-roboto;
  }

  td.ant-table-cell span {
    color: inherit !important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  a,
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  span,
  input,
  td,
  th,
  div,
  button {
    @apply font-roboto;
  }

  ::placeholder {
    @apply font-roboto;
  }

  ::selection {
    @apply bg-primary text-white;
  }

  ::-webkit-scrollbar {
    width: 15px;
    height: 15px;
  }

  ::-webkit-scrollbar-track {
    background: #f8f8f8;
    border: 1px solid #f0f0f0;
  }

  ::-webkit-scrollbar-thumb {
    border: 4px solid #f8f8f8;
    background: #c4c4c4;
    @apply rounded-lg;
  }
  .ant-input:focus {
    @apply !shadow-none;
  }

  .hiden-scrollbar::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  .hiden-scrollbar::-webkit-scrollbar-track {
    background: #f8f8f8;
    border: 1px solid #f0f0f0;
  }

  .hiden-scrollbar::-webkit-scrollbar-thumb {
    border: 4px solid #f8f8f8;
    background: #c4c4c4;
    @apply rounded-lg;
  }
}

@layer components {
  .green {
    --primaryColor: 0 88 111;
    --thirdColor: #2a7487;
    --hoverColor: #a3d365;
  }

  .gray {
    --primaryColor: 41 49 58;
    --thirdColor: #283848;
    --hoverColor: #344b5f;
  }

  .blue {
    --primaryColor: 0 82 204;
    --thirdColor: #3375d6;
    --hoverColor: #02a886;
    --sidebar_itemColor: #ffffff;
  }
  .red {
    --primaryColor: 219 56 51;
    --thirdColor: #df6561;
    --hoverColor: #ffffff;
    --sidebar_itemColor: #ffffff;
    --hoverTextColor: #db3833;
  }

  .truncated {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .text-tooltip {
    z-index: 1000;
    position: absolute;
    visibility: hidden;
    opacity: 0;
    transition: opacity 1s ease;
    bottom: 4%;
    left: 40%;
    padding: 10px;
    white-space: nowrap;
    border-radius: 2px;
    background: #f4f4f4;
    color: #000;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
  }

  .text-truncate:hover + .text-tooltip {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
  }

  .ant-select-single.ant-select-show-arrow .ant-select-selection-search {
    @apply !pr-[10px];
  }

  /* Custom resizable */
  .react-resizable {
    position: relative;
    background-clip: padding-box;
  }

  .react-resizable-handle {
    position: absolute;
    right: -5px;
    bottom: 0;
    z-index: 1;
    background-image: none !important;
    width: 10px !important;
    height: 100% !important;
    /* cursor: col-resize; */
  }

  .ant-picker-focused {
    @apply !shadow-none;
  }
  .ant-select-focused .ant-select-selector {
    @apply !shadow-none;
  }
}
.ant-input-number-focused {
  box-shadow: none !important;
}

.ant-input-affix-wrapper-focused {
  box-shadow: none !important;
}

.ant-input-disabled,
.ant-input-number-disabled,
.ant-select-disabled .ant-select-selector,
.ant-picker-input > input[disabled],
.ant-checkbox-disabled + span {
  color: inherit !important;
}

/* Custom modal confirm ant */
.ant-modal-confirm-btns button,
.custom__modalconfirm--delete .ant-modal-content {
  border-radius: 2px;
}
/* PC */
.custom__modalconfirm--delete .ant-modal-content {
  white-space: nowrap;
  min-width: fit-content;
}
.ant-btn-primary {
  box-shadow: none !important;
}

.custom__modalconfirm--unblock .ant-btn-primary {
  @apply bg-[#389e0d] rounded-sm;
}

.custom__modalconfirm--unblock .ant-btn-primary:hover {
  @apply brightness-110 !bg-[#389e0d];
}

.custom__modalconfirm--lock .ant-btn-primary {
  @apply bg-[#ef4444] rounded-sm;
}

.custom__modalconfirm--lock .ant-btn-primary:hover {
  @apply brightness-110 !bg-[#ef4444];
}
